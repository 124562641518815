<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./img/顶部背景.png" alt="" />
    </div>
    <div v-if="isshow" class="box">
      <div class="daohang">
        <a
          class="dabiaoti"
          v-for="(item, index) in biaotidata"
          :key="index"
          :class="{ dabiaotibf: index == current }"
          @click="change(index)"
          >{{ item.title }}</a
        >
        <!-- <a class="dabiaoti">智慧能源典型案例</a>
        <a class="dabiaoti">智慧交通典型案例</a> -->
      </div>
      <div class="anlibox">
        <div
          class="anli"
          v-for="(item, index) in this.anlidata"
          :key="index"
          @click="go(item)"
        >
          <img
            style="width: 645px; height: 370px; border-radius: 15px 15px 0 0"
            :src="item.content.dataList[0].url[0].url"
            alt=""
          />
          <div
            style="
              margin-top: 24px;
              margin-bottom: 16px;
              font-size: 26px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.9);
            "
          >
            {{ item.content.dataList[0].title }}
          </div>
          <div
            style="
              margin-left: 24px;
              margin-right: 24px;
              font-size: 17px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6);
              line-height: 38px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            "
          >
            {{ item.content.dataList[0].content }}
          </div>
        </div>
        <!-- 两个div也许会集中 -->
        <div style="width: 650px"></div>
      </div>
    </div>

    <div v-if="!isshow" style="width: 100%;
  height: 100%;
  background: #f2f5f8;
  display: flex;
  justify-content: center;">
      <div class="xiangqing">
        <div class="title">{{this.editorText.content.dataList[0].title}}</div>
        <div class="jieshao">
          {{ this.editorText.content.dataList[0].content }}
        </div>
        <div>
          <img
            style="width: 1186px;  margin-top: 36px;margin-bottom: 64px;"
            :src="this.editorText.content.dataList[0].url[0].url"
            alt=""
          />
        </div>
      </div>

      <div style="position: fixed; right: 10px; bottom: 144px">
      <div v-if="!isshow" @click="fanhui" class="fhsj">
        <div class="fanhui">
          <img src="@/assets/返回上级蓝.png" alt="" />
        </div>
        <div>Return</div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { getContents } from "@/api/index.js";
export default {
  data() {
    return {
      editorText:'',
      isshow:true,
      current: 0, //选中项
      anlidata: [
        {
          url: require("./img/麻城1.jpg"),
          title: "麻城夫子河20MWP设施农业光伏电站项目",
          content:
            "麻城夫子河20MWP设施农业光伏电站项目，项目场址位于麻城市夫子河镇西程塆村和李胜村，总投资额1.4亿，占地面积600亩，日发电量最高10万度。该项目于2016年6月20日正式开工，2016年12月22日完成并网发电任务。",
          router: "/typicalCaseDetails",
        },
        {
          url: require("./img/中建壹品阅江府21.jpg"),
          title: "中建壹品投资发展有限公司充电桩工程集中采购项目",
          content:
            "中建壹品投资发展有限公司2022年度充电桩工程集中采购项目，烽火富华负责协议期内中建壹品在建以及待建工程充电桩工程的供货及安装，累计已安装充电枪3180把，设备型号为FH-EA-7KW。",
          router: "/typicalCaseDetails",
        },
        {
          url: require("./img/零碳大楼1.png"),
          title: "国网湖北电科院零碳大楼项目",
          content:
            "国网湖北电科院零碳大楼项目，该项目是湖北地区第一座具备办公自动化、智能化，能源绿色化，通信系统高性能化，建筑柔性化，零碳排放的“零碳大楼”。项目充分应用充分应用“大、云、物、移、智”等现代信息技术，将楼宇分布式光伏、风电、储能、市电、供水系统、冷热源（空调）等能源数据；大楼消防、安防、办公环境、实验室环境等业务数据，通过智慧能源数字系统实现互联互通，形成能源、业务、数据的汇集及分析处理。",
          router: "/typicalCaseDetails",
        },
        {
          url: require("./img/大冶1.jpg"),
          title: "大冶市电动汽车充电设施建设项目",
          content:
            "大冶市电动汽车充电设施建设项目，该项目新建电动汽车充电枪 1373 把，配套设施包括：智能功率调度充电系统安装工程、智能功率调度充电系统、软件安装工程、停车位膜结构雨棚安装工程、线缆安装工程、箱变高压部分等。设备型号包括FH-ED-120KW、FH-EK-240KW、FH-EK-360KW、FH-EK-480KW。",
          router: "/typicalCaseDetails",
        },

        {
          url: require("./img/秦皇岛案例1.jpg"),
          title: "秦皇岛青龙公交项目",
          content:
            "秦皇岛青龙公交项目，项目地址位于河北省秦皇岛市，新建电动汽车充电枪 80 把，设备型号为FH-ED-60KW。",
          router: "/typicalCaseDetails",
        },
      ],
      biaotidata: [
        { title: "Smart energy" },
        { title: "Intelligent building" },
        { title: "Intelligent transportation" },
      ],
    };
  },
  methods: {
    fanhui() {
      this.isshow = !this.isshow;
    },
    async change(x) {
      this.current = x;
      if (x == 1) {
        let params = {
          menuId: "2089",
          id: "",
        };
        const res = await getContents(params);
        for (let index = 0; index < res.length; index++) {
          res[index].content = JSON.parse(res[index].content);
        }
        this.anlidata = res;
        // this.anlidata = [
        //   {
        //     url: require("./img/双子星案例.png"),
        //     title: "枣庄双子星广场智慧楼宇",
        //     content:
        //       "项⽬智慧楼宇系统⼯程建设估算总投资约1.4亿元⼈⺠币。智慧楼宇系统主要包括以下弱电及智能化系统：信息设施 系统、公共安全系统、建筑设备管理系统、智能化集成系统、机房⼯程。",
        //     router: "/typicalCaseDetails",
        //   },
        //   {
        //     url: require("./img/国网.png"),
        //     title: "国网湖北计量中心泛在电力物联网系统及软件平台",
        //     content:
        //       "项⽬主要包含分布式光伏系统、全钒液流储能系统、实验室温湿度⾃动控制系统、充电桩系统、园区视频监控及智能 停⻋系统、⽴体仓库全景监控系统、能耗管理系统、智能访客及⼈员管理系统、实验室电磁波及震动监测系统、智慧 ......",
        //     router: "/typicalCaseDetails",
        //   },
        //   {
        //     url: require("./img/武汉.png"),
        //     title: "武汉舵落口广场",
        //     content:
        //       "项⽬位于武汉市舵落⼝⼤市场，总建筑⾯积197780平⽅⽶。智能化建设内容包括：⽹络系统、⻔禁系统、视频监控系统、⽆线对讲系统、信息发布系统、楼宇⾃控 系统、停⻋场管理系统、公共⼴播系统、会议系统、电梯五⽅对讲系统...",
        //     router: "/typicalCaseDetails",
        //   },
        //   {
        //     url: require("./img/中南.png"),
        //     title: "中南设计研究总院有限公司设计大厦",
        //     content:
        //       "项⽬位于武汉市江岸区解放公园路，总建筑⾯积4.5万平⽅⽶。智能化建设内容包括：综合布线、计算机⽹络、电话交换、有线电视、背景⾳乐、视频监控、防盗报警、⽆线对讲巡更、⻔禁⼀卡通、访客通道管理、停⻋场管理、信息发布...",
        //     router: "/typicalCaseDetails",
        //   },
        // ];
      } else if (x == 0) {
        let params = {
          menuId: "2088",
          id: "",
        };
        const res = await getContents(params);
        for (let index = 0; index < res.length; index++) {
          res[index].content = JSON.parse(res[index].content);
        }
        this.anlidata = res;
      } else if (x == 2) {
        let params = {
          menuId: "2090",
          id: "",
        };
        const res = await getContents(params);
        for (let index = 0; index < res.length; index++) {
          res[index].content = JSON.parse(res[index].content);
        }
        this.anlidata = res;
      }
    },
    go(index) {
      console.log(index);
      this.isshow = !this.isshow;
      this.editorText = index;
      // this.$router.push({ path: index.router, query: { key: index } });
    },
  },
  mounted(){
    this.change(0)
  }
};
</script>

<style scoped>
::v-deep.el-backtop {
  width: 82px;
  height: 82px;
}
.fhsj {
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  /* text-align: center; */
  width: 82px;
  height: 82px;
  background: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.fhsj:hover {
  cursor: pointer;
  background: #0940fd;
  color: rgba(255, 255, 255, 0.6);
  .fanhui img {
    content: url("../../../assets/返回上级.png");
  }
}

.title {
  font-size: 38px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  /* line-height: 0px; */
  margin-top: 54px;
  margin-bottom: 36px;
}
.jieshao {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;
  width: 1186px;
}
.xiangqing {
  width: 1314px;
  margin-top: 64px;
  margin-bottom: 88px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box {
  padding-left: 15%;
  padding-right: 15%;
  background: #f2f5f8;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.daohang {
  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-top: 54px;
  margin-bottom: 54px;
  width: 926px;
  height: 102px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #ffffff;
  cursor: pointer;
}
.dabiaoti {
  font-size: 26px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.dabiaoti:hover {
  font-size: 26px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.dabiaotibf {
  font-size: 26px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
/* .dabiaoti:hover::after {
  content: "";
  border-bottom: 20px solid #0940FD;
} */
.anlibox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;
  margin-bottom: 64px;
}
.anli {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 645px;
  height: 580px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  margin-bottom: 24px;
}
</style>